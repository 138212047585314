<template>
  <div class="photo-page">
    <div class="photo-page__top">
      <button class="photo-page__back-mobile" @click="goToTerm">
        <img src="@/assets/img/chevron-left.svg" />
      </button>

      <button
        type="button"
        @click="goToTerm"
        class="back photo-page__back-desktop"
      >
        <div class="back__icon">
          <img src="@/assets/img/arrow-left.svg" />
        </div>
        <div class="back__text">
          {{ $t("term.back") }}
        </div>
      </button>

      <div class="photo-page__title">{{ $t("photo.facePosition") }}</div>
    </div>

    <div class="photo-page__video-hole" />

    <div class="photo-page__video-wrapper">
      <WebCam
        class="photo-page__video"
        ref="webcam"
        :device-id="deviceId"
        @cameras="onCameras"
        @video-live="onCameraReady"
      />
    </div>

    <div class="photo-page__bottom">
      <button
        @click="onCapture"
        class="button photo-page__btn"
        :style="{ backgroundColor: primaryColor }"
        :disabled="!isCameraReady"
      >
        {{ $t("photo.takePhotography") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { WebCam } from "vue-web-cam";

export default {
  components: {
    WebCam,
  },

  data() {
    return {
      deviceId: "0",
      devices: [],
      isCameraReady: false,
    };
  },

  computed: {
    ...mapState({
      primaryColor: ({ themes }) => themes.primaryColor,
    }),
    device: function () {
      return this.devices.find((n) => n.value === this.deviceId);
    },
  },

  watch: {
    devices: function () {
      const [first] = this.devices;
      if (first) {
        this.deviceId = first.value;
      }
    },
  },

  methods: {
    onCapture() {
      this.$store.commit("photo/setPhoto", this.$refs.webcam.capture());
      this.$store.commit("photo/setPhotoMode", "webcam");

      this.goToCheckPhoto();
    },

    onCameras(cameras) {
      const devices = [];
      cameras.forEach((device) => {
        devices.push({
          label: device.label,
          value: device.deviceId,
        });
      });

      this.devices = devices;
    },

    goToTerm() {
      this.$store.commit("mode/setMode", "term");
    },

    goToCheckPhoto() {
      this.$store.commit("mode/setMode", "checkPhoto");
    },

    onCameraReady() {
      this.isCameraReady = true;
    },
  },
};
</script>
