import actions from "./actions";
import mutations from "./mutations";

export default {
  state: () => ({
    isPending: false,
    isSuccess: false,
    isFailure: false,
    errorMessages: "",

    base64Photo: "",
    resizeBase64Photo: "",

    mode: "",
  }),

  actions,
  mutations,
  namespaced: true,
};
