import Vue from "vue";
import VueI18n from "vue-i18n";
const ru = require("../lang/ru.json");
const en = require("../lang/en.json");

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language,
  messages: {
    ru,
    en,
  },
});

export default i18n;
