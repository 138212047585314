import axios from "@/plugins/vue-axios";

export default {
  check({ commit }, photo) {
    commit("setAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    return axios
      .post("check-photo", {
        photo,
      })
      .then(() => {
        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });
      })
      .catch((error) => {
        const {
          response: {
            data: {
              status: { message },
            },
          },
        } = error;

        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });

        let errorMessage = message;

        commit("setErrors", errorMessage);
      });
  },

  reset({ commit }) {
    commit("setAsyncStatus", {
      isPending: false,
      isSuccess: false,
      isFailure: false,
    });

    commit("setPhoto", "");
    commit("setPhotoMode", "");
    commit("setResizePhoto", "");
    commit("setErrors", "");
  },
};
