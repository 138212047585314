export default {
  setAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPending = isPending;
    state.isSuccess = isSuccess;
    state.isFailure = isFailure;
  },

  setQRAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPendingQR = isPending;
    state.isSuccessQR = isSuccess;
    state.isFailureQR = isFailure;
  },

  setRegistrationAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPendingRegistration = isPending;
    state.isSuccessRegistration = isSuccess;
    state.isFailureRegistration = isFailure;
  },

  setRegistrationErrors(state, errors) {
    state.errorMessagesRegistration = errors;
  },

  setUser(
    state,
    {
      id,
      name,
      company,
      department,
      ownerName,
      description,
      dueFrom,
      dueTo,
      isFaceAdded,
      isRegistered,
      photo,
      faceAccess,
      isOnlyByQR,
    }
  ) {
    state.id = id;
    state.name = name;
    state.company = company;
    state.department = department;
    state.ownerName = ownerName;
    state.description = description;
    state.dueFrom = dueFrom;
    state.dueTo = dueTo;
    state.isFaceAdded = isFaceAdded;
    state.isRegistered = isRegistered;
    state.photo = photo;
    state.faceAccess = faceAccess;
    state.isOnlyByQR = isOnlyByQR;
  },

  setPhoto(state, photo) {
    state.photo = photo;
  },

  setQR(state, { qr }) {
    state.qr = qr;
  },

  setCode(state, { code }) {
    state.code = code;
  },
};
