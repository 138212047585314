<template>
  <div id="app" :class="['main', { 'main--p-0': mode === 'photo' }]">
    <div v-if="isPending || isPendingAuth" class="main__loading">
      <div class="main__loading-wrapper">
        <img src="@/assets/img/search.svg" class="main__loading-icon" />
        <div class="main__loading-text">
          {{ $t("main.searchPlace") }}
        </div>
      </div>
    </div>

    <div v-else class="main__wrapper">
      <AccessDenied v-if="isFailureAuth" />

      <template v-else>
        <LocaleSwitcher />
        <Main v-if="mode === 'main'" />
        <Term v-if="mode === 'term'" />
        <Photo v-if="mode === 'photo'" />
        <CheckPhoto v-if="mode === 'checkPhoto'" />
        <Done v-if="mode === 'done'" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Main from "@/pages/Main";
import Term from "@/pages/Term";
import Photo from "@/pages/Photo";
import CheckPhoto from "@/pages/CheckPhoto";
import Done from "@/pages/Done";

import LocaleSwitcher from "@/components/LocaleSwitcher";

import AccessDenied from "@/pages/AccessDenied";

export default {
  name: "App",

  components: {
    Main,
    Term,
    Photo,
    CheckPhoto,
    Done,
    AccessDenied,
    LocaleSwitcher,
  },

  methods: {
    getUser() {
      const paramsString = location.search;
      const searchParams = new URLSearchParams(paramsString);
      const code = searchParams.get("code");

      this.$store.commit("user/setCode", { code });
      this.$store.dispatch("user/getUser", this.code);
    },
  },

  computed: mapState({
    isPending: ({ themes }) => themes.isPending,
    isPendingAuth: ({ user }) => user.isPending,
    isSuccessAuth: ({ user }) => user.isSuccess,
    isFailureAuth: ({ user }) => user.isFailure,
    code: ({ user }) => user.code,
    mode: ({ mode }) => mode.mode,
  }),

  created() {
    this.$store.dispatch("themes/getThemes");
    this.getUser();
  },
};
</script>

<style lang="scss">
@import "./assets/scss/app.scss";
</style>
