<template>
  <div class="done-page">
    <img v-if="isPending" src="@/assets/img/loader.svg" />

    <div v-if="isFailure && !errorMessages" class="done-page__error">
      {{ $t("messages.error") }}
    </div>

    <div v-if="isFailure && errorMessages" class="done-page__error">
      {{ $t(`messages.${errorMessages}`) }}
    </div>

    <div class="done-page__btn" v-if="isFailure">
      <button
        class="button"
        :style="{ backgroundColor: primaryColor }"
        @click="goToTerm"
      >
        {{ $t("button.onMain") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState({
    primaryColor: ({ themes }) => themes.primaryColor,
    isPending: ({ user }) => user.isPendingRegistration,
    isSuccess: ({ user }) => user.isSuccessRegistration,
    isFailure: ({ user }) => user.isFailureRegistration,
    errorMessages: ({ user }) => user.errorMessagesRegistration,
    resizeBase64Photo: ({ photo }) => photo.resizeBase64Photo,
    code: ({ user }) => user.code,
  }),

  watch: {
    isSuccess() {
      this.$store.commit("mode/setMode", "main");
    },
  },

  methods: {
    goToTerm() {
      this.$store.commit("mode/setMode", "term");
    },

    async registration() {
      await this.$store.dispatch("user/registration", this.resizeBase64Photo);

      if (this.isSuccess) {
        this.$store.dispatch("user/getUser", this.code);
      }
    },
  },

  created() {
    this.registration();
  },
};
</script>
