<template>
  <div
    :class="[
      'alert',
      {
        'alert--success': type === 'success',
        'alert--danger': type === 'danger',
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
};
</script>
