import actions from "./actions";
import mutations from "./mutations";

export default {
  state: () => ({
    isPending: false,
    isSuccess: false,
    isFailure: false,

    isPendingRegistration: false,
    isSuccessRegistration: false,
    isFailureRegistration: false,
    errorMessagesRegistration: "",

    isPendingQR: false,
    isSuccessQR: false,
    isFailureQR: false,

    code: "",

    id: 0,
    name: "",
    department: "",
    ownerName: "",
    description: "",
    dueFrom: "",
    dueTo: "",
    qr: "",
    isFaceAdded: false,
    isRegistered: false,
    photo: null,
    faceAccess: 0,
    isOnlyByQR: false,
  }),

  actions,
  mutations,
  namespaced: true,
};
