import Vue from "vue";
import App from "./App.vue";
import store from "./store";

// Plugins
import "./plugins/vue-fullscreen";
import "./plugins/vue-moment";
import i18n from "./plugins/vue-i18n";
import "./plugins/vue-axios";

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
