<template>
  <div class="access-denied">
    <div class="access-denied__text">
      {{ $t("accessDenied.message") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessDenied",
};
</script>
