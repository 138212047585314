import axios from "@/plugins/vue-axios";

export default {
  getUser({ commit }, code) {
    commit("setAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    return axios
      .post("auth", { code })
      .then((response) => {
        const {
          data: {
            data: {
              id,
              name,
              department,
              owner_name,
              description,
              due_from,
              due_to,
              token,
              is_face_added,
              is_registered,
              photo,
              face_access,
              only_by_qr,
            },
          },
        } = response;

        axios.defaults.headers.Authorization = `Bearer ${token}`;

        commit("setUser", {
          id,
          name,
          department,
          ownerName: owner_name,
          description,
          dueFrom: due_from,
          dueTo: due_to,
          isFaceAdded: is_face_added,
          isRegistered: is_registered,
          photo: photo ? `data:image/png;base64,${photo}` : "",
          faceAccess: face_access,
          isOnlyByQR: only_by_qr,
        });

        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });
      })
      .catch(() => {
        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });
      });
  },

  getQR({ commit }) {
    commit("setQRAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    axios("qr")
      .then((response) => {
        const {
          data: {
            data: { qr },
          },
        } = response;

        commit("setQR", {
          qr,
        });

        commit("setQRAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });
      })
      .catch(() => {
        commit("setQRAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });
      });
  },

  registration({ commit, state }, photo) {
    commit("setRegistrationAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    return axios
      .post(`registration/${state.id}`, {
        photo,
      })
      .then(() => {
        commit("setRegistrationAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });

        commit("setPhoto", photo);
      })
      .catch((error) => {
        const {
          response: {
            data: {
              status: { message },
            },
          },
        } = error;

        commit("setRegistrationAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });

        let errorMessage = message;

        if (errorMessage.match(/Face match found User/)) {
          errorMessage = "faceMatchFoundUser";
        }

        commit("setRegistrationErrors", errorMessage);
      });
  },
};
