<template>
  <div class="main-page">
    <div class="main-page__header">
      <div class="main-page__logo-wrapper">
        <img :src="logo" class="main-page__logo" />
      </div>
      <div v-click-outside="hideUserContext" class="main-page__user-wrapper">
        <button
          type="button"
          class="main-page__user-btn"
          :style="{ color: primaryColor }"
          @click="toggleUserContext"
        >
          <div
            v-if="photo"
            class="main-page__user-photo"
            :style="`background-image: url(${photo})`"
          />
          <div
            v-else
            class="main-page__user-photo main-page__user-photo--placeholder"
          />
        </button>
        <div
          :class="[
            'main-page__user-context',
            { 'main-page__user-context--show': isShowUserContext },
          ]"
        >
          <div
            class="main-page__user-context-item main-page__user-context-item--title"
          >
            {{ name }}
          </div>
          <div class="main-page__user-context-item">
            <button
              type="button"
              class="button button--min"
              :style="{ backgroundColor: primaryColor }"
              @click="goToPhoto"
            >
              <template v-if="photo">
                {{ $t("term.retakePhoto") }}
              </template>
              <template v-else>
                {{ $t("term.takePhoto") }}
              </template>
            </button>
          </div>
          <div class="main-page__user-context-item">
            <input
              v-show="false"
              id="uploadPhoto"
              type="file"
              accept="image/*"
              @change="onUploadPhoto"
            />
            <label
              for="uploadPhoto"
              class="button button--min button--label-btn"
              :style="{ color: primaryColor }"
              >{{ $t("term.chooseFromGallery") }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="isPending" class="main-page__loading">
      <div class="main-page__loading-wrapper">
        <img src="@/assets/img/search.svg" class="main-page__loading-icon" />
        <div class="main-page__loading-text">
          {{ $t("main.searchPlace") }}
        </div>
      </div>
    </div>

    <template v-else>
      <Alert
        v-if="isSuccessRegistration"
        type="success"
        class="main-page__message"
      >
        {{ $t("messages.successReg") }}
      </Alert>

      <Alert
        v-if="faceAccess === 0 && isFaceAdded"
        type="danger"
        class="main-page__message"
      >
        {{ $t("messages.faceNotAccess") }}
      </Alert>

      <Alert v-if="faceAccess === 1 && isFaceAdded" class="main-page__message">
        {{ $t("messages.faceAccessProgress") }}
      </Alert>

      <div class="main-page__content">
        <div class="main-page__left">
          <div class="main-page__place main-page__place--show-mobile">
            <div class="main-page__comment">
              {{ ownerName }} <br />
              {{ department }}
              <template v-if="description">({{ description }})</template>
            </div>
            <div class="main-page__due" v-html="due" />
          </div>

          <div class="main-page__qr-wrapper">
            <fullscreen
              v-model="isQrFullscreen"
              fullscreenClass="main-page__qr"
            >
              <img
                @click="qrFullscreen"
                :src="`data:image/png;base64,${qr}`"
                alt="qr"
                class="main-page__qr-image"
              />
            </fullscreen>
            <div class="main-page__download-qr">
              <a
                :href="`data:image/png;base64,${qr}`"
                download="qr.png"
                class="main-page__download-qr-btn"
                :style="{ color: primaryColor }"
              >
                {{ $t("main.saveQR") }}
              </a>
            </div>
          </div>
        </div>

        <div class="main-page__right">
          <div class="main-page__place main-page__place--show-tablet">
            <div class="main-page__comment">
              {{ ownerName }} <br />
              {{ department }}
              <template v-if="description">({{ description }})</template>
            </div>
            <div class="main-page__due" v-html="due" />
          </div>

          <div v-if="!isFaceAdded" class="main-page__add-photo-message">
            {{ $t("main.addPhotoMessage") }}
          </div>

          <div class="main-page__add-photo-btns">
            <button
              v-if="!isFaceAdded"
              type="button"
              class="button button--min"
              :style="{ backgroundColor: primaryColor }"
              @click="goToPhoto"
            >
              <template v-if="photo">
                {{ $t("term.retakePhoto") }}
              </template>
              <template v-else>
                {{ $t("term.takePhoto") }}
              </template>
            </button>
            <input
              v-show="false"
              id="uploadPhoto"
              type="file"
              accept="image/*"
              @change="onUploadPhoto"
            />
            <label
              v-if="!isFaceAdded"
              for="uploadPhoto"
              class="button button--min button--label-btn"
              :style="{ color: primaryColor }"
              >{{ $t("term.chooseFromGallery") }}</label
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import Alert from "@/components/Alert";

export default {
  directives: {
    ClickOutside,
  },

  components: { Alert },

  data() {
    return {
      isQrFullscreen: false,
      isShowUserContext: false,
    };
  },

  methods: {
    qrFullscreen() {
      this.isQrFullscreen = !this.isQrFullscreen;
    },

    goToTerm() {
      this.$store.commit("mode/setMode", "term");
    },

    goToPhoto() {
      this.$store.commit("mode/setMode", "photo");
    },

    onUploadPhoto(e) {
      const photoBlob = e.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(photoBlob);

      reader.onload = () => {
        this.$store.commit("photo/setPhoto", reader.result);
        this.$store.commit("photo/setPhotoMode", "upload");
        this.$store.commit("mode/setMode", "checkPhoto");
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    },

    hideUserContext() {
      this.isShowUserContext = false;
    },

    toggleUserContext() {
      this.isShowUserContext = !this.isShowUserContext;
    },
  },

  computed: {
    ...mapState({
      logo: ({ themes }) => themes.logo,
      primaryColor: ({ themes }) => themes.primaryColor,
      isPending: ({ user }) => user.isPendingQR,
      isSuccessRegistration: ({ user }) => user.isSuccessRegistration,
      name: ({ user }) => user.name,
      department: ({ user }) => user.department,
      ownerName: ({ user }) => user.ownerName,
      description: ({ user }) => user.description,
      dueFrom: ({ user }) => user.dueFrom,
      dueTo: ({ user }) => user.dueTo,
      qr: ({ user }) => user.qr,
      isFaceAdded: ({ user }) => user.isFaceAdded,
      photo: ({ user }) => user.photo,
      faceAccess: ({ user }) => user.faceAccess,
    }),

    due() {
      const tz = this.$moment.tz.guess();
      const dueFromMinutesOffset = this.$moment
        .tz(this.dueFrom, tz)
        .utcOffset();
      const dueToMinutesOffset = this.$moment.tz(this.dueTo, tz).utcOffset();
      const dateFormat = "DD.MM.YYYY HH:mm";

      let text = "";

      if (this.dueFrom) {
        text += `<span class="main-page__due-start">${this.$t(
          "main.from"
        )} ${this.$moment(this.dueFrom)
          .add(dueFromMinutesOffset, "minutes")
          .format(dateFormat)} </span>`;
      }

      if (this.dueTo) {
        text += `<span class="main-page__due-end">${this.$t(
          "main.to"
        )} ${this.$moment(this.dueTo)
          .add(dueToMinutesOffset, "minutes")
          .format(dateFormat)}</span>`;
      }

      if (!this.dueFrom && !this.dueTo) {
        text = this.$t("main.indefinite");
      }

      return text;
    },
  },

  created() {
    this.$store.dispatch("user/getQR");
  },
};
</script>
