<template>
  <div class="term-page">
    <div class="term-page__top-text">
      <p v-if="name" class="term-page__top-text-title">
        {{ $t("main.hello") }}, <strong>{{ name }}</strong>
      </p>

      <p v-if="department" class="term-page__top-text-subtitle">
        {{ $t("main.inviteTo") }} <strong>{{ department }}</strong>
        <template v-if="description"> ({{ description }}) </template>
      </p>

      <p v-if="ownerName" class="term-page__top-text-subtitle">
        {{ $t("main.inviter") }} <strong>{{ ownerName }}</strong>
      </p>
    </div>

    <div class="term-page__title">{{ $t("term.title") }}</div>

    <div class="term-page__term-block">
      <div class="term-page__term">
        <label for="term" class="term-page__term-label"
          >{{ $t("term.termAgree") }}
          <button
            type="button"
            class="term-page__term-link"
            :style="{ color: primaryColor }"
            @click="isTermDialogShow = true"
          >
            {{ $t("term.userAgreement") }}
          </button>
        </label>
        <input
          v-model="term"
          type="checkbox"
          id="term"
          name="term"
          class="term-page__term-checkbox"
        />

        <Dialog
          :is-visible="isTermDialogShow"
          @onClickBackstage="isTermDialogShow = false"
        >
          <Term />
        </Dialog>
      </div>

      <div class="term-page__term">
        <label for="personal-data" class="term-page__term-label"
          >{{ $t("term.termAgree") }}
          <button
            type="button"
            class="term-page__term-link"
            :style="{ color: primaryColor }"
            @click="isPersonalDialogShow = true"
          >
            {{ $t("term.personalDataPolicies") }}
          </button>
        </label>
        <input
          v-model="personalData"
          type="checkbox"
          id="personal-data"
          name="term"
          class="term-page__term-checkbox"
        />

        <Dialog
          :is-visible="isPersonalDialogShow"
          @onClickBackstage="isPersonalDialogShow = false"
        >
          <PersonalData />
        </Dialog>
      </div>

      <div v-if="!isOnlyByQR" class="term-page__button">
        <button
          :style="{ backgroundColor: primaryColor }"
          class="button"
          :disabled="!personalData || !term"
          @click="goToPhoto"
        >
          {{ $t("term.takePhoto") }}
        </button>

        <input
          v-show="false"
          id="uploadPhoto"
          type="file"
          accept="image/*"
          :disabled="!personalData || !term"
          @change="onUploadPhoto"
        />
        <label
          for="uploadPhoto"
          class="button"
          :style="{ color: primaryColor }"
          >{{ $t("term.chooseFromGallery") }}</label
        >
      </div>
      <div v-else class="term-page__button">
        <button
          :style="{ backgroundColor: primaryColor }"
          class="button"
          :disabled="!personalData || !term"
          @click="goToMain"
        >
          {{ $t("common.continue") }}
        </button>
      </div>
    </div>

    <div v-if="!isOnlyByQR" class="term-page__bottom">
      <button
        class="button button--invisible-grey"
        :disabled="!personalData || !term"
        @click="goToMain"
      >
        {{ $t("term.cancelTakePhoto") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Dialog from "@/components/Dialog";
import PersonalData from "@/components/term/PersonalData";
import Term from "@/components/term/Term";

export default {
  components: { Dialog, PersonalData, Term },

  data() {
    return {
      term: false,
      personalData: false,
      isPersonalDialogShow: false,
      isTermDialogShow: false,
    };
  },

  methods: {
    checkRegistered() {
      if (this.isRegistered || this.isSuccessQR) {
        this.goToMain();
      }
    },

    goToMain() {
      this.$store.commit("mode/setMode", "main");
    },

    goToPhoto() {
      this.$store.commit("mode/setMode", "photo");
    },

    onUploadPhoto(e) {
      const photoBlob = e.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(photoBlob);

      reader.onload = () => {
        this.$store.commit("photo/setPhoto", reader.result);
        this.$store.commit("photo/setPhotoMode", "upload");
        this.$store.commit("mode/setMode", "checkPhoto");
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    },
  },

  computed: mapState({
    primaryColor: ({ themes }) => themes.primaryColor,
    name: ({ user }) => user.name,
    department: ({ user }) => user.department,
    ownerName: ({ user }) => user.ownerName,
    description: ({ user }) => user.description,
    isRegistered: ({ user }) => user.isRegistered,
    isSuccessQR: ({ user }) => user.isSuccessQR,
    isOnlyByQR: ({ user }) => user.isOnlyByQR,
  }),

  created() {
    this.checkRegistered();
  },
};
</script>
