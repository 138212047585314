<template>
  <transition name="dialog-fade">
    <div v-if="isVisible" class="dialog">
      <div class="dialog__back" @click="onClickBackstage" />
      <div class="dialog__window-wrapper">
        <button class="dialog__close" @click="onClickBackstage" />
        <div class="dialog__window">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Dialog",

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClickBackstage() {
      this.$emit("onClickBackstage");
    },
  },
};
</script>
