import Vue from "vue";
import Vuex from "vuex";

// Modules
import themes from "./modules/themes";
import mode from "./modules/mode";
import user from "./modules/user";
import photo from "./modules/photo";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    themes,
    mode,
    user,
    photo,
  },
});
