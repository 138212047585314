import mutations from "./mutations";

export default {
  state: () => ({
    mode: "term",
  }),

  mutations,
  namespaced: true,
};
