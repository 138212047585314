<template>
  <div class="locale-switcher">
    <button class="button button--min button--grey" @click="switchLocale">
      {{ lang }}
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LocaleSwitcher",

  methods: {
    switchLocale() {
      switch (this.$i18n.locale) {
        case "en":
          this.$i18n.locale = "ru";
          break;
        case "ru":
        case "ru-RU":
          this.$i18n.locale = "en";
          break;
        default:
          this.$i18n.locale = "ru";
      }
    },
  },

  computed: {
    ...mapState({
      primaryColor: ({ themes }) => themes.primaryColor,
    }),

    lang() {
      switch (this.$i18n.locale) {
        case "en":
          return "Русский";
        case "ru":
        case "ru-RU":
          return "English";
        default:
          return "Русский";
      }
    },
  },
};
</script>
