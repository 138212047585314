<template>
  <div class="check-photo-page">
    <button class="check-photo-page__back-mobile" @click="goToPhoto">
      <img src="@/assets/img/chevron-left.svg" />
    </button>

    <button
      type="button"
      @click="goToPhoto"
      class="back check-photo-page__back-desktop"
    >
      <div class="back__icon">
        <img src="@/assets/img/arrow-left.svg" />
      </div>
      <div class="back__text">
        {{ $t("term.back") }}
      </div>
    </button>

    <div v-if="isFailure && !errorMessages" class="check-photo-page__title">
      {{ $t("messages.error") }}
    </div>

    <div v-if="isFailure && errorMessages" class="check-photo-page__title">
      <template>
        {{ getLangMessage(errorMessages) }}
      </template>
    </div>

    <div
      :class="[
        'check-photo-page__img',
        { 'check-photo-page__img--mirrored': mode === 'webcam' },
      ]"
    >
      <img :src="resizeBase64Photo" />
    </div>

    <div v-if="isPending" class="check-photo-page__loader">
      <img src="@/assets/img/loader.svg" />
    </div>

    <div v-if="isFailure" class="check-photo-page__btns">
      <button class="button button--danger" @click="goToPhoto">
        {{ $t("checkPhoto.takePhotoAgain") }}
      </button>

      <input
        v-show="false"
        id="uploadPhoto"
        type="file"
        accept="image/*"
        @change="onUploadPhoto"
      />
      <label
        for="uploadPhoto"
        class="button"
        :style="{ color: primaryColor }"
        >{{ $t("term.chooseFromGallery") }}</label
      >
    </div>

    <canvas v-show="false" ref="resizeCanvas" width="1000" height="1000" />
  </div>
</template>

<script>
import faceValidate from "@/mixins/faceValidate";
import { mapState } from "vuex";

export default {
  mixins: [faceValidate],

  data() {
    return {
      resizeBase64Photo: "",
    };
  },

  methods: {
    resizeImage(base64Photo) {
      const canvas = this.$refs.resizeCanvas;
      const ctx = canvas.getContext("2d");
      const maxW = 1000;
      const maxH = 1000;

      var img = new Image();
      img.onload = () => {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min(maxW / iw, maxH / ih);
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        this.resizeBase64Photo = canvas.toDataURL("image/jpeg", 0.8);
        this.$store.commit("photo/setResizePhoto", this.resizeBase64Photo);

        this.check();
      };
      img.src = base64Photo;
    },

    goToPhoto() {
      this.$store.commit("mode/setMode", "photo");
      this.$store.dispatch("photo/reset");
    },

    goToDone() {
      this.$store.commit("mode/setMode", "done");
    },

    async check() {
      await this.$store.dispatch("photo/check", this.resizeBase64Photo);

      if (this.isSuccess) {
        this.goToDone();
      }
    },

    onUploadPhoto(e) {
      const photoBlob = e.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(photoBlob);

      reader.onload = () => {
        this.$store.dispatch("photo/reset");
        this.$store.commit("photo/setPhoto", reader.result);
        this.$store.commit("photo/setPhotoMode", "upload");
        this.resizeImage(this.base64Photo);
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    },
  },

  computed: mapState({
    primaryColor: ({ themes }) => themes.primaryColor,
    base64Photo: ({ photo }) => photo.base64Photo,
    isPending: ({ photo }) => photo.isPending,
    isSuccess: ({ photo }) => photo.isSuccess,
    isFailure: ({ photo }) => photo.isFailure,
    errorMessages: ({ photo }) => photo.errorMessages,
    mode: ({ photo }) => photo.mode,
  }),

  mounted() {
    this.resizeImage(this.base64Photo);
  },
};
</script>
