export default {
  setPhoto(state, photo) {
    state.base64Photo = photo;
  },

  setPhotoMode(state, mode) {
    state.mode = mode;
  },

  setResizePhoto(state, photo) {
    state.resizeBase64Photo = photo;
  },

  setAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPending = isPending;
    state.isSuccess = isSuccess;
    state.isFailure = isFailure;
  },

  setErrors(state, errors) {
    state.errorMessages = errors;
  },
};
